.nav {
  background-color: black;
  display: flex;
  padding: 0 5vw;
  font-family: "Gilroy";
  font-weight: 600;
  align-items: center;
  width: 100vw;
  color: white;
  height: 10vh;
}

.nav>div {
  flex: 1;
  text-align: center;
}

.nav>div:first-child {
  text-align: left;
}

.nav>div:last-child {
  text-align: right;
}

.navLogoContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navLogoContainer img {
  height: 5vh;
}

.mainContainer {
  display: flex;
}


.nav_select {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  color: #e3d5d5;
  background-color: #202020;
  border: 1px solid #ccc;
  border-radius: 5px;
  appearance: none;
  cursor: pointer;
}

.dashboardContainer {
  padding: 5vh 5vw;
  font-family: "TT Norms Pro";
  background-color: #ddd;
  height: 90vh;
  width: 100%;
  overflow: auto;
}

.dashboardContainer h1 {
  font-size: 2rem;
  font-family: "Gilroy";
}

.infoCont {
  display: flex;
  flex-wrap: wrap;
  /* height: 20vh; */
  margin: 5vh 0;
  justify-content: space-evenly;
}

.infoBox {
  width: 20vw;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: 3vh;
  border: 3px solid var(--accent-color);
  border-radius: 20px;
  justify-content: center;
  text-align: center;
}

.infoBox h2 {
  font-family: "Gilroy";
  font-size: 3rem;
}

.refreshButton {
  background-color: var(--accent-color);
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.sidebar {
  position: sticky;
  top: 10vh;
  width: 20vw;
  padding: 2vw;
}

.sidebar li {
  list-style-type: none;
  font-family: "TT Norms Pro";
  font-size: 1.2rem;
  cursor: pointer;
  margin-bottom: 20px;
}

.sidebar a {
  color: black;
}

.dashboardContainer select,
.dashboardContainer option {
  background-color: var(--accent-color);
  margin: 20px 0;
  padding: 5px 10px;
  font-family: "Gilroy";
  font-size: 1.1rem;
  border: none;
}

.entryexitcontainer {
  display: flex;
  gap: 10px;
  margin: 2vh 2vw;
}

.entryexitcontainer .infoBox {
  width: 12vw;
}

.toggle_switch {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 20px;
  border-radius: 34px;
  background-color: #ccc;
  position: relative;
  transition: background-color 0.3s;
  padding: 5px;
  margin: 5px 0px;
}

.plain {
  background-color: #ccc;
}

.cumulative {
  background-color: var(--accent-color);
}

.switch {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  transition: transform 0.3s;
}

.plain .switch {
  transform: translateX(0);
}

.cumulative .switch {
  transform: translateX(calc(40px - 50%));
}

.label {
  position: absolute;
  /* left: 10px; */
  transition: left 0.3s;
}

.cumulative .label {
  left: 10px;
}

.plain .label {
  left: 42px;
}

.options_tab {
  display: flex;
  justify-content: space-around !important;
  align-items: center;
  margin: 2vh 2vw;
}

.dwn-btn {
  width: 4%;
  position: relative;
  left: 93%;
  text-align: center;
  border: none;
  background-color: #dddddd;

  img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {

  .dwn-btn {
    width: 5%;
  }

  .dashboardContainer {
    width: 100%;
  }

  .mainContainer {
    width: 100%;
    flex-direction: column;
  }

  .infoCont {
    display: flex;
    flex-wrap: wrap;

    .infoBox {
      width: 100%;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  }

  .updated,
  .location {
    font-size: 10px;
  }

  .pagetag {
    font-size: 25px !important;
  }

  /* .linechart {} */
}