.home-product-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 40vh;
  padding: 3vh 2vw;
  border-radius: 10px;
  background-repeat: no-repeat !important;
  background-size: cover;
  overflow: hidden;
  position: relative;
  background-size: cover !important;
}

.home-product-card-text {
  color: white;
  text-align: left;
  flex: 3 1 0;
  position: relative;
  z-index: 5;
}

.home-product-card-text h1 {
  font-family: "Gilroy";
  font-weight: 700;
  letter-spacing: 0.08rem;
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.home-product-card-text p {
  font-family: "TT Norms Pro";
  font-weight: 500;
  letter-spacing: 0.04rem;
}

.home-product-card-cta {
  flex: 1 1 0;
  text-align: right;
  color: white;
  position: relative;
  z-index: 5;
}

.home-product-card-cta p {
  font-family: "Gilroy";
  font-weight: 700;
  letter-spacing: 0.03rem;
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.home-product-card-cta button {
  background-color: var(--accent-color);
  font-family: "Gilroy";
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
}

.home-product-card-overlay {
  background: linear-gradient(rgb(0, 0, 0, 0), rgb(0, 0, 0, 0.5));
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .home-product-card-text h1 {
    font-size: 1rem;
  }

  .home-product-card-text p {
    font-size: 0.75rem;
    font-weight: 400;
  }

  .home-product-card-cta p {
    font-size: 1rem;
  }

  .home-product-card-cta button {
    font-size: 0.75rem;
    padding: 5px 10px;
  }
}