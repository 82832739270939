.teamcard {
  width: 20vw;
  color: white;
}

.teamcard img {
  width: 100%;
}

.teamcard>h1 {
  font-family: "Gilroy";
  font-size: 1.5rem;
  letter-spacing: 0.04rem;
}

.teamcard a {
  font-family: "Gilroy";
  font-weight: 600;
  font-size: 1.25rem;
  color: white;
  text-decoration: underline;
}

.designation {
  font-family: "Gilroy";
  font-weight: 600;
  font-size: 1.25rem;
  letter-spacing: 0.04rem;
  color: var(--accent-color);
  margin-bottom: 10px;
}

.email {
  font-family: "Gilroy";
  font-weight: 600;
  letter-spacing: 0.04rem;
}

.email a {
  transition: 0.3s;
  text-decoration: none;
  word-wrap: break-word;
  font-size: 1rem;
}

.email a:hover {
  color: var(--accent-color);
}

.flex {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .teamcard {
    width: 80vw;
  }
}