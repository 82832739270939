/* Hero Container */

.home-hero-container {
  background: url("/public/Images/home-hero.png");
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: grid;
  place-items: center;
}

.home-hero-container h1 {
  font-size: 4rem;
  font-family: "Gilroy";
  font-weight: 700;
  color: white;
  text-align: center;
  text-transform: capitalize;
  width: 70vw;
  letter-spacing: 0.08rem;
}

@media screen and (max-width: 768px) {
  .home-hero-container h1 {
    width: unset;
    font-size: 2.5rem;
  }
}

/* About Container */
.home-about-container {
  display: flex;
  flex-direction: column;
  padding: 10vh 10vw;
  align-items: center;
  gap: 3vh;
  background-color: var(--background-color);
  transition: 0.3s;
  color: var(--primary-color);
}

.home-about-container h1 {
  font-size: 2rem;
  letter-spacing: 0.02rem;
  font-family: "Gilroy";
  font-weight: 700;
}

.home-about-container p {
  font-family: TT Norms Pro;
  font-weight: 500;
  letter-spacing: .04rem;
  margin: 0 5vw;
  text-align: justify;
}

.home-about-container button {
  font-family: "TT Norms Pro";
  letter-spacing: 0.04rem;
  width: max-content;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--primary-color);
}

.home-about-container button:hover {
  color: var(--accent-color);
}

.home-about-container img {
  margin-top: 7vh;
  width: 40vw;
}

@media screen and (max-width: 768px) {
  .home-about-container {
    padding: 10vh 5vw;
  }

  .home-about-container h1 {
    font-size: 1.5rem;
  }

  .home-about-container p {
    text-align: left;
    margin: 0;
  }

  .home-about-container img {
    width: 80vw;
    margin-top: 5vh;
  }
}

/* Cards Container */

.home-cards-container {
  background-color: var(--background-color);
  transition: 0.3s;
  text-align: center;
  padding: 10vh 0;
  color: var(--primary-color);
}

.home-cards-container>h1 {
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 3rem;
  letter-spacing: 0.08rem;
}

.home-cards-container>p {
  font-family: "TT Norms Pro";
  font-size: 1.25rem;
}

.home-cards {
  padding: 5vh 10vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5vw;
  row-gap: 5vh;
}

.home-contact {
  display: none;
}

@media screen and (max-width: 768px) {
  .home-hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 10vh 5vw;
    gap: 15vh;
    height: 90vh;
    position: relative;
  }

  .home-hero-container h1 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .home-cards-container>h1 {
    font-size: 2rem;
  }

  .home-cards {
    grid-template-columns: 1fr;
  }

  .home-contact {
    display: block;
    width: 60%;
  }

  .home-contact button {
    padding: 10px 20px;
    background-color: var(--accent-color);
    color: black;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    font-family: "Gilroy";
    font-weight: 600;
    width: 100%;
  }
}