.sidebar {
  position: sticky;
  top: 10vh;
  width: 20vw;
  padding: 2vw;
}

.sidebar li {
  list-style-type: none;
  font-family: "TT Norms Pro";
  font-size: 1.2rem;
  cursor: pointer;
  margin-bottom: 20px;
}

.sidebar a {
  color: black;
  font-family: "TT Norms Pro";
  font-size: 1.2rem;
}

.device-clock{
  position: absolute;
  top: 15%;
  left: 80%;
  z-index: 1000;
  font-size: 1.2rem;
}

.nav {
  background-color: black;
  display: flex;
  padding: 0 5vw;
  font-family: "Gilroy";
  font-weight: 600;
  align-items: center;
  width: 100vw;
  color: white;
  height: 10vh;
}

.nav>div {
  flex: 1;
  text-align: center;
}

.nav>div:first-child {
  text-align: left;
}

.nav>div:last-child {
  text-align: right;
}

.navLogoContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navLogoContainer img {
  height: 5vh;
}

.dashboardContainer {
  padding: 5vh 5vw;
  font-family: "TT Norms Pro";
  background-color: #ddd;
  height: 90vh;
  width: 100%;
  overflow: auto;
}

.dashboardContainer h1 {
  font-size: 2rem;
  font-family: "Gilroy";
}

.lineChartContainer {
  position: relative;
  height: 70vh;
  width: 70vw;
}

.mainContainer {
  display: flex;
}

.mapContainer {
  height: 50vh;
  margin: 5vh 0;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {


  .dashboardContainer {
    width: 100%;
  }

  .mainContainer {
    flex-direction: column;
  }

  .infoBox {
    width: 40vw;
  }

  .navLogoContainer {
    gap: 5px;
  }

  .navLogoContainer img {
    height: unset;
    width: 10vw;
  }

  .lineChartContainer {
    height: 70vh;
    width: 90vw;
  }

  .flex {
    flex-direction: column;
    gap: 10px;
  }
}