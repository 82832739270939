:root {
  --background-color: #fff;
  --primary-color: #000;
  --accent-color: #81cb50;
}

.dark-theme {
  --background-color: #111;
  --primary-color: #fff;
  --accent-color: #81cb50;
}

.accent-color {
  color: var(--accent-color);
}

a {
  text-decoration: none;
  transition: 0.3s;
}

a,
button {
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

ul,
li {
  margin: unset;
  padding: unset;
}