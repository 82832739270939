.main_con {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 90vh;
}

.login_section {
  background-color: #81cb50;
}

.photo_con {
  width: 40vw;
  height: 90vh;
  overflow: hidden;
}

.login_photo {
  height: inherit;
  width: auto;
  overflow: hidden;
}

.info_con {
  width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  font-size: 60px;
  /* font-style: bold; */
  font-weight: 700;
  font-family: "Gilroy";
}

.login_btn {
  width: 20rem;
  height: 1.8rem;
  border: 1px solid white;
  border-radius: 5px;
  background-color: #81cb50;
  margin-bottom: 1.3rem;
  color: white;
  font-family: "TT Norms Pro";
  font-size: 16px;
  /* padding: 20px 0px; */
}

.login_legends {
  font-family: "TT Norms Pro";
  color: black;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0.2rem;
  position: relative;
  /* left: -9.5rem; */
}

.login_inputs {
  width: 20rem;
  height: 1.8rem;
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 1.3rem;
  padding: 0.3rem;
  color: black;
}

.login_redirect span {
  color: white;
  font-family: "TT Norms Pro";
}

.login_redirect {
  font-family: "TT Norms Pro";
}

.body_con {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.login_navbar {
  height: 10vh;
  width: 100vw;
  padding: 0 5vw;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login_navbar>div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.login_navbar p {
  font-family: "TT Norms Pro";
}

.login_svg {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.signup_warning {
  font-family: "TT Norms Pro";
}

.loaderImg-holder {
  display: flex;
  justify-content: center;
  align-items: center;

}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.signup-page-loaderImg {
  height: 1.7rem;
  animation: spin 4s linear infinite;
}



@media (max-width : 800px) {
  .photo_con {
    display: none;
  }

  .info_con {
    width: 100vw;
  }

  .header_login_screen {
    display: none;
  }

  .login_navbar>div {
    justify-content: center;
    width: 100%;
  }
}

@media (max-width : 450px) {
  .heading {
    font-size: 45px;
  }

  .login_inputs {
    width: 15rem;
  }

  .login_btn {
    width: 15rem;
    font-size: 12px;
  }
}

@media (max-width : 360px) {
  .login_navbar>div>img {
    height: 35px;
  }

  .heading {
    font-size: 40px;
    /* margin-bottom: 14px; */
  }

  .login_legends {
    font-size: 12px;
  }

  .login_redirect,
  .signup_warning {
    font-size: 15px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}