.footer {
    background-color: black;
    padding: 10vh 10vw;
    color: white;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10vw;
    font-family: "TT Norms Pro";
    font-weight: 500;
    letter-spacing: 0.03rem;
}

.footer a {
    text-decoration: underline;
    color: white;
    transition: 0.3s;
    cursor: pointer;
}

.footer a:hover {
    color: var(--accent-color);
}

.footer-link {
    text-decoration: none !important;
}

.footer hr {
    border: none;
    border-top: 1px solid var(--accent-color);
}

.footer-child:nth-child(1) {
    align-items: baseline;
}

.footer-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 3vh;
    text-align: left;
}

.footer-child>img {
    height: 5vh;
}

.footer-text {
    font-weight: 400;
}

.footer-links {
    display: flex;
    justify-content: space-between;
}

.footer-link {
    flex: 1;
    position: relative;
    cursor: pointer;
}

.footer-contact {
    display: flex;
    gap: 10px;
}

.footer-link-expand {
    display: flex;
    position: absolute;
    flex-direction: column;
    gap: 10px;
    max-height: 0px;
    overflow: hidden;
    /* padding: 10px 5px; */
    background-color: var(--accent-color);
    transition: 0.3s;
    width: max-content;
}

.footer-link-clicked {
    max-height: unset;
    padding: 10px 5px;
}

.footer-link-expand a {
    color: black;
}

.footer-link-expand a:hover {
    color: black;
}

@media screen and (max-width: 768px) {
    .footer {
        flex-direction: column;
        gap: 10vh;
    }

    .footer-child {
        width: 100%;
    }
}