.nav {
  background-color: black;
  display: flex;
  padding: 0 5vw;
  font-family: "Gilroy";
  font-weight: 600;
  align-items: center;
  width: 100vw;
  color: white;
  height: 10vh;
}

.nav>div {
  flex: 1;
  text-align: center;
}

.nav>div:first-child {
  text-align: left;
}

.nav>div:last-child {
  text-align: right;
}

.navLogoContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navLogoContainer img {
  height: 5vh;
}

.nav_select {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  color: #e3d5d5;
  background-color: #202020;
  border: 1px solid #ccc;
  border-radius: 5px;
  appearance: none;
  cursor: pointer;
}

.signoutbtn {
  border: none;
  background: black;
  color: white;
}

@media screen and (max-width: 768px) {
  /* .nav {
    width: 100%;
  } */

  .navLogoContainer {
    width: 30% !important;

    img {
      width: 100%;
    }
  }

  .name {
    font-size: 10px;
  }

  .nav_select {
    width: 75px !important;
    margin-top: 0px;
    font-size: 10px !important;
  }

  .signoutbtn {
    font-size: 10px;
    padding: 0px;
    width: 20%;
  }
}