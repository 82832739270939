.navbar {
    width: 100vw;
    padding: 2vh 5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(rgb(0 0 0 / 0.7), rgb(0 0 0 / 0));
    backdrop-filter: blur(5px);
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
}

.navbar-logo {
    width: 15vw;
}

.nav-links {
    display: flex;
    gap: 30px;
}

.nav-link {
    font-family: "Gilroy";
    font-weight: 600;
    text-decoration: none;
    color: white;
}

.navbar-btn-container {
    text-align: right;
}

.navbar-btn {
    padding: 15px 30px;
    margin-left: 10px;
    font-family: "Gilroy";
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    border-radius: 8px;
    background-color: var(--accent-color);
    cursor: pointer;
}

.navbar-mobile-menu-icon {
    display: none;
}

.navbar-open {
    display: none;
}

.navbar-theme-mode {
    overflow: hidden;
    height: 30px;
    cursor: pointer;
}

.navbar-theme-icons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    transform: unset;
    transition: 0.5s ease-in-out;
}

.navbar-theme-icon {
    width: 30px;
    height: 30px;
}

.theme-transition {
    transform: translateY(-40px);
}

@media screen and (max-width: 768px) {

    .nav-links,
    .navbar-btn-container {
        display: none;
    }

    .navbar-logo {
        width: 40vw;
    }

    .navbar-mobile-menu-icon {
        display: unset;
        height: 3vh;
    }

    .navbar-open {
        position: fixed;
        z-index: 999;
        background: linear-gradient(black, rgb(0, 0, 0, 0.5));
        backdrop-filter: blur(10px);
        top: -50vh;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 2vh 5vw;
        transition: 0.5s ease-in-out;
    }

    .navbar-open>div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 3vh;
    }

    .navbar-open>.nav-links {
        display: flex;
        flex-direction: column;
        gap: 2vh;
        padding: 4vh 0;
        align-items: start;
        margin-bottom: 0;
    }

    .navbar-menu-close {
        height: 3vh;
        width: 3vh;
    }

    .navbar-theme-mode {
        overflow: hidden;
        height: 25px;
        cursor: pointer;
    }

    .navbar-theme-icons {
        display: flex;
        flex-direction: column;
        gap: 10px;
        transform: unset;
        transition: 0.5s ease-in-out;
    }

    .navbar-theme-icon {
        width: 25px;
        height: 25px;
    }

    .theme-transition {
        transform: translateY(-35px);
    }
}