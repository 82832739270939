@font-face {
  font-family: "Gilroy";
  src: url("./Assets/fonts/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  src: url("./Assets/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Gilroy";
  src: url("./Assets/fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("./Assets/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("./Assets/fonts/TT Norms Pro Regular.otf") format("opentype");
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("./Assets/fonts/TT Norms Pro Medium.otf") format("opentype");
  font-weight: 500;
}