.aboutHero {
  background: url("/public/Images/about-hero.png") no-repeat center;
  background-size: cover;
  padding: 10vh 10vw;
  display: grid;
  place-items: center;
  font-family: "Gilroy";
  font-weight: 700;
  letter-spacing: 0.06rem;
  font-size: 3.5rem;
  height: 40vh;
  color: white;
}

.aboutCompanyContainer {
  padding: 10vh 5vw;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  background-color: var(--background-color);
  transition: 0.3s;
  color: var(--primary-color);
}

.aboutCompany {
  display: flex;
  align-items: center;
}

.aboutCompany>div {
  flex: 1;
}

.aboutCompany h1 {
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 3vh;
  letter-spacing: 0.04rem;
}

.aboutCompany p {
  font-family: "TT Norms Pro";
  font-weight: 500;
  font-size: 1.1rem;
  text-align: justify;
}

.reverse {
  flex-direction: row-reverse;
}

.aboutTeam {
  background-color: #f0f0f0;
  padding: 5vh 5vw;
}

.aboutTeam>h1 {
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 3rem;
  letter-spacing: 0.04rem;
  margin-bottom: 3vh;
}

.teamcards {
  display: flex;
  gap: 5vw;
}

@media screen and (max-width: 768px) {
  .aboutHero {
    height: 60vh;
    font-size: 2.5rem;
  }

  .aboutCompany>div {
    flex: unset;
  }

  .aboutTeam>h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .teamcards {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10vh;
  }
}