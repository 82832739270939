.lineChartContainer {
  position: relative;
  height: 70vh;
  width: 70vw;
}

.typeSelector {
  float: right;
}

.linechart {
  display: table;
  margin: auto;
}

.canvass {
  width: 283px;
  height: 248px;
}

@media screen and (max-width: 768px) {
  .lineChartContainer {
    width: 100px;

    .canvass {
      width: 100% !important;
      margin: 0px;
      padding: 0px;
      display: flex !important;
      flex-wrap: wrap;
      height: 500px !important;
    }
  }
}